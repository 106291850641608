import { useStaticQuery, graphql } from "gatsby"

const useLogo = () => {
    const { logo } = useStaticQuery(
        graphql`
          query {
            logo: file(absolutePath: { regex: "/logo.png/" }) {
                childImageSharp {
                    fixed(width: 60, height: 50, quality: 95) {
                        src
                        width
                        height
                    }
                }
            }
          }
        `
    )

    return logo?.childImageSharp?.fixed
}

export default useLogo