import React from 'react'
import useLockBodyScroll from '../hooks/useLockBodyScroll'
import { Link } from 'gatsby'
import Bounds from './Bounds'
import Flex from './UI/Flex'
import { BsX as HideMenuIcon } from 'react-icons/bs'
import Text from './UI/Text'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

const MenuLink = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    color: white;

    &:focus, &:active, &:visited {
        color: white;
    }

    &:hover {
        text-decoration: underline;
    }
`

const Container = styled(Flex)`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3000;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
`

const MenuIconWrapper = styled(Flex)`
    &:hover {
        cursor: pointer;
    }
`

export default function Menu({
    close
}) {
    useLockBodyScroll()

    const data = useStaticQuery(graphql`
        query AllPagesHeaderQuery {
            allMarkdownRemark(
                filter: { fields: { type: { eq: "pages" } } }
            ) {
                nodes {
                    frontmatter {
                        title
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    `)

    const links = data?.allMarkdownRemark?.nodes?.map(node => ({ slug: node.fields.slug, title: node.frontmatter.title })) || []

    return (
        <Container bg="rgba(0, 0, 0, 0.9)" flexWrap="wrap" py={2} onClick={() => close(false)}>
            <Bounds flex="1" pt="2">
                {links.map(link => (
                    <Text
                        key={link.slug}
                        fontSize={[4, 4, 5]}
                        mb="3"
                        fontWeight="bold"
                        color="shine"
                        onClick={() => close(false)}
                    >
                        <MenuLink to={link.slug}>{link.title}</MenuLink>
                    </Text>
                ))}

                <MenuIconWrapper m="auto" mb="0" pt="3" onClick={() => close(false)}>
                    <Flex my="auto">
                        <HideMenuIcon size={45} color="white" />
                    </Flex>
                    <Text
                        fontSize={[5]}
                        ml="1"
                        fontWeight="bold"
                        color="shine"
                    >Stäng</Text>
                </MenuIconWrapper>
            </Bounds>
        </Container>
    )
}