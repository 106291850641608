import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-image"
import Heading from "./UI/Heading"
// import Text from './UI/Text'
import Box from './UI/Box'

const Img = styled(Image)`
    height: 285px;
    border-radius: 3px 3px 0 0;

    @media (min-width: 700px) {
        border-radius: 0;
        box-shadow: 0 30px 60px -10px rgba(0,0,0,0.22), 0 18px 36px -18px rgba(0,0,0,0.25);
    }
`

const Container = styled(Box)`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 5px;
    z-index: 1;

    a {
        text-decoration: none;
    }

    @media (max-width: 700px) {
        article {
            background: white;
            border-radius: 3px;
            box-shadow: 0 30px 60px -10px rgba(0,0,0,0.22), 0 18px 36px -18px rgba(0,0,0,0.25);
        }

        &:last-child {
            margin: 0;
        }
    }
`

// const StyledText = styled(Text)`
//     text-overflow: ellipsis;
//     overflow-wrap: normal;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     display: -webkit-box;
// `

export default ({
    page,
    mr,
    ml
}) => {
    const title = page.frontmatter.title || page.fields.slug
    const featuredImgFluid = page.frontmatter.featuredImage?.childImageSharp.fluid

    return (
        <Container ml={ml} mr={mr}>
            <Link to={page.fields.slug} itemProp="url">
                {featuredImgFluid && (
                    <Box mx={[-5, -4, 0]}>
                        <Img fluid={featuredImgFluid} />
                    </Box>
                )}
                <Box p={0}>
                    <header>
                        <Heading variant="dark" mt={[2, 2, 2, 3]}>
                            <span itemProp="headline">{title}</span>
                        </Heading>
                        {/* <small>{page.frontmatter.date}</small> */}
                    </header>

                    {/* <section>
                        <StyledText variant="dark" dangerouslySetInnerHTML={{ __html: page.frontmatter.description || page.excerpt }} itemProp="description" />
                    </section> */}
                </Box>
            </Link>
        </Container>
    )
}