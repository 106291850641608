import React from "react"
import styled from "styled-components"
import Heading from "../components/UI/Heading"
import PageImageLink from "../components/pageImageLink"
import Flex from "./UI/Flex"
import Box from "./UI/Box"
import Bounds from "./Bounds"

const Pages = styled(Box)`
    list-style-type: none;
    display: grid;
    column-gap: 30px;
`

// &[data-position="left"] {
//     grid-template-columns: 1fr 457px;
// }

// &[data-position="right"] {

// }

// grid-template-columns: 1fr 1fr;

// @media (max-width: 700px) {
//     grid-template-columns: 1fr;
//     flex-direction: column;
// }

const Featured = ({pages}) => {
    let groupedPages = []

    pages.map((page, index) => {
        if (index % 2 === 0) {
            groupedPages = [...groupedPages, []]
        }

        groupedPages[groupedPages.length - 1].push(page)

        return null
    })

  return (
        <Box mt={5}>
            {groupedPages.map((gPages, index) => (
                <Flex
                    key={index}
                    mb={[0, 2]}
                    flexDirection={["column", "column", "row"]}
                >
                    {gPages.map((page, index) => (
                        <Flex mb={5} flex="1">
                            <PageImageLink
                                key={page.fields.slug}
                                page={page}
                                ml={[0, 0, index % 2 === 0 ? 0 : 3]}
                                mr={[0, 0, index % 2 === 1 || gPages.length === 1 ? 0 : 3]}
                            />
                        </Flex>
                    ))}
                </Flex>
            ))}
        </Box>
    )
}

export default Featured