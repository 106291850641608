import styled from '@emotion/styled'
import Box from './Box'
import { position, opacity } from 'styled-system'

const FixedBackground = styled(Box)`
    ${position};
    ${opacity};

    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

FixedBackground.propTypes = {
    ...position.propTypes,
    ...opacity.propTypes
}

export default FixedBackground
