import React from "react"
import rehypeReact from "rehype-react"
import { Link } from 'gatsby'

import Featured from "../components/featured"
import Header from "../components/header"
import Footer from "../components/footer"
import Bounds from "../components/Bounds"
import Flex from "../components/UI/Flex"
import Box from "../components/UI/Box"
import Heading from "../components/UI/Heading"
import Text from "../components/UI/Text"
import Img from "../components/UI/Img"
import Offers from "../components/offers"
import StaticForm from "../components/StaticForm"
import StreetMap from "../components/map"
import FixedBackground from "../components/UI/FixedBackground"

const render = ({
    children,
    as,
    ...rest
}, Component) => {
    let props = {}

    Object.keys(rest).map(key => {
        const newKey = key.replace(/(-)([a-z])/g, (
            character => character.toUpperCase()
        )).replace(/-/g, '')

        const parseString = value => {


            if (typeof value === "string") {
                try {
                    const json = JSON.parse(value)

                    if (Array.isArray(json) || typeof json === "object") {
                        return json
                    }
                } catch(e) {}

                if (value.indexOf('-') < 0 && value.indexOf('%') < 0 && value.indexOf('px') < 0) {
                    return isNaN(parseFloat(value)) ? value : parseFloat(value)
                }
            }

            return value
        }

        props[newKey] = parseString(rest[key])

        return null
    })

    if (children && children.length) {
        return (
            <Component as={as} {...props}>
                {children}
            </Component>
        )
    }

    return <Component as={as} {...props} />
}

const useRenderAst = () => htmlAst => {
    const tree = new rehypeReact({
        createElement: React.createElement,
        components: {
            header: props => render(props, Header),
            footer: props => render(props, Footer),
            featured: props => render(props, Featured),
            headline: props => render(props, Heading),
            h1: props => render({as: 'h1', ...props}, Heading),
            h2: props => render({as: 'h2', ...props}, Heading),
            h3: props => render({as: 'h3', ...props}, Heading),
            h4: props => render({as: 'h4', ...props}, Heading),
            h5: props => render({as: 'h5', ...props}, Heading),
            h6: props => render({as: 'h6', ...props}, Heading),
            p: props => render({as: 'p', ...props}, Text),
            offers: props => render(props, Offers),
            bounds: props => render(props, Bounds),
            flex: props => render(props, Flex),
            box: props => render(props, Box),
            fixedbg: props => render(props, FixedBackground),
            map: props => render(props, StreetMap),
            a: props => render(props, props.to ? Link : ({children, ...rest}) => <a {...rest}>{children}</a>),
            img: props => console.log("img", props) || render(props, Img),
            staticform: props => render(props, StaticForm)
        }
    }).Compiler(htmlAst)

    return tree.type === 'div' ? (
		<>{tree.props.children}</>
	) : tree
}

export default useRenderAst