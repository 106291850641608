import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Text from './UI/Text'
import Flex from './UI/Flex'
import Input from './UI/Input'

const getDefaultContact = (accessKey) => ({
    name: '',
    email: '',
    subject: '',
    honeypot: '',
    message: '',
    replyTo: '@',
    accessKey
})

const StaticForm = ({
    subject,
    accessKey,
    ...rest
}) => {
    const [mailSent, setMailSent] = useState(false)
    const [contact, setContact] = useState(getDefaultContact(accessKey))

    const handleSubmit = async (e) => {
        e.preventDefault()

        const res = await fetch('https://api.staticforms.xyz/submit', {
            method: 'POST',
            body: JSON.stringify(contact),
            headers: { 'Content-Type': 'application/json' }
        })

        if (res.status === 200) {
            setMailSent(true)
            setContact(getDefaultContact(accessKey))
        }
    }

    useEffect(() => {
        let timeoutId

        if (mailSent) {
            timeoutId = window.setTimeout(() => setMailSent(false), 5000)
        }

        return () => window.clearTimeout(timeoutId)
    }, [mailSent])

    const handleChange = e => setContact({ ...contact, [e.target.name]: e.target.value })

    return (
        <>
            {mailSent && <Text>Ditt meddelande har skickats! 🎉</Text>}

            <Flex
                as="form"
                flexDirection="column"
                onSubmit={handleSubmit}
            >
                <Input
                    type='text'
                    mb={4}
                    placeholder='Vad gäller det?'
                    name='subject'
                    onChange={handleChange}
                    border="1px solid #333"
                    required
                />
                <Input
                    type='text'
                    mb={4}
                    placeholder='Ditt namn'
                    name='name'
                    onChange={handleChange}
                    border="1px solid #333"
                    required
                />
                <Input
                    type='email'
                    mb={4}
                    placeholder='Din mailadress'
                    name='email'
                    onChange={handleChange}
                    border="1px solid #333"
                    required
                />
                <Input
                    type='text'
                    mb={4}
                    name='honeypot'
                    style={{ display: 'none' }}
                    border="1px solid #333"
                    onChange={handleChange}
                />
                <textarea
                    placeholder='Meddelande'
                    name='message'
                    onChange={handleChange}
                    required
                />

                <button type='submit'>Skicka</button>
            </Flex>
        </>
    )
}

export default StaticForm