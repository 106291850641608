import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { BsList as DisplayMenuIcon } from 'react-icons/bs'
import Flex from './UI/Flex'
import Heading from './UI/Heading'
import Bounds from './Bounds'
import useLogo from '../hooks/useLogo'
import useSiteMetadata from "../hooks/useSiteMetadata"
import Menu from './Menu'

const Image = styled.img`
    margin: auto 0;
`

const LogoLink = styled(Link)`
    display: flex;
    text-decoration: none;
    color: #111;

    &:hover,
    &:focus {
        text-decoration: none;
        color: #111;
    }
`

const MenuIconWrapper = styled(Flex)`
    &:hover {
        cursor: pointer;
    }
`

export default () => {
    const logo = useLogo()
    const { title } = useSiteMetadata()
    const [displayMenu, setDisplayMenu] = useState(false)

    return (
        <Flex as="header" bg="darkened" mb={3} py={2}>
            <Bounds height="auto" flex="1">
                <Flex>
                    <LogoLink to="/">
                        {logo && (
                            <Image
                                alt="logo"
                                src={logo.src}
                                width={logo.width}
                                height={logo.height}
                            />
                        )}

                        <Heading as="h1" variant="light" m={0} fontSize={[2, 2, 3, 4]}>{title}</Heading>
                    </LogoLink>

                    <MenuIconWrapper m="auto" mr={0} onClick={() => setDisplayMenu(true)}>
                        <DisplayMenuIcon size={24} color="white" />
                    </MenuIconWrapper>
                </Flex>
            </Bounds>

            {displayMenu && <Menu close={() => setDisplayMenu(false)} />}
        </Flex>
    )
}