import React from "react"
import styled from "styled-components"
import Flex from "./UI/Flex"
import Text from "./UI/Text"
import useLogo from "../hooks/useLogo"
import useSiteMetadata from "../hooks/useSiteMetadata"

const Image = styled.img`
    margin: auto 0;
`

export default () => {
    const logo = useLogo()
    const { description, siteUrl } = useSiteMetadata()

    return (
        <Flex as="footer" height="100%" mt={6} p={5} bg="darkened">
            <Flex>
                <Text variant="light">© {new Date().getFullYear()}, {siteUrl.replace('https://', '')} - {description}</Text>
                {logo && (
                    <Image
                        alt="logo"
                        src={logo.src}
                        width={logo.width / 2}
                        height={logo.height / 2}
                    />
                )}
            </Flex>
        </Flex>
    )
}