import React from 'react'
// import { Map, TileLayer } from 'react-leaflet'

export default () => <div>map</div>

// export default ({
//     position = [0, 0],
//     zoom = 1
// }) => typeof window !== 'undefined' ? (
//     <Map center={position} zoom={zoom}>
//         <TileLayer
//             url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
//             attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
//         />
//     </Map>
// ) : null

// {this.props.markerText !== "" &&
// <Marker position={this.props.position}>
//     <Popup>{this.props.markerText}</Popup>
// </Marker>
// }