import styled from '@emotion/styled'
import { background, boxShadow, border, width, height, minWidth, maxWidth, space, color, display } from 'styled-system'
import { colorStyle, variant } from '@styled-system/variant'

const Box = styled.div`
    ${colorStyle};
    ${background};
    ${boxShadow};
    ${border};
    ${width};
    ${height};
    ${minWidth};
    ${maxWidth};
    ${space};
    ${color};
    ${display};

    ${variant({
        variants: {
            light: {
                backgroundColor: 'bright'
            },
            dark: {
                backgroundColor: 'dark'
            }
        }
    })}
`

Box.propTypes = {
    ...background.propTypes,
    ...border.propTypes,
    ...width.propTypes,
    ...height.propTypes,
    ...maxWidth.propTypes,
    ...minWidth.propTypes,
    ...space.propTypes,
    ...color.propTypes,
    ...display.propTypes,
    ...boxShadow.propTypes
}

export default Box
