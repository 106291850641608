import React from "react"
import styled from "styled-components"

const List = styled.ul`
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    display: flex;
`

const Li = styled.li`
    font-size: 30px;
    margin: 0 5px;
`

const offerEmojis = {
    coffee: { text: "Kaffe", value: "☕️" },
    icecream: { text: "Glass", value: "🍦" },
    bistro: { text: "Lätt mat", value: "🥯" },
    pool: { text: "Pool", value: "⛱" },
    restaurant: { text: "Restaurang", value: "🍽" },
    salad: { text: "Sallad", value: "🥗" }
}

const Offers = (props) => (
    <List>
        { Object.keys(props).map(key => {
            if (offerEmojis.hasOwnProperty(key)) {
                return (
                    <Li>
                        <span>{offerEmojis[key].value}</span>
                        <span></span>
                    </Li>
                )
            }

            return null
        }).filter(el => el) }
    </List>
)

export default Offers