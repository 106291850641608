import React from 'react'
import Flex from './UI/Flex'

export default ({
    children,
    ...rest
}) => (
    <Flex flexDirection="column" px={[3, 4, 5]} mx={[null, null, null, "auto"]} maxWidth="1220px" style={{overflowX: "hidden"}} {...rest}>
        {children}
    </Flex>
)