import styled from '@emotion/styled'
import Box from './Box'
import { flex, position, flexDirection, flexWrap } from 'styled-system'

const Flex = styled(Box)`
    display: flex;
    ${flex};
    ${flexDirection};
    ${flexWrap};
    ${position};
`

Flex.propTypes = {
    ...flex.propTypes,
    ...flexDirection.propTypes,
    ...flexWrap.propTypes,
    ...position.propTypes
}

export default Flex
