import { graphql } from 'gatsby'
import ClassicLayout from '../components/layouts/Classic'

export default ClassicLayout

export const pageQuery = graphql`
  query Page(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      htmlAst
      frontmatter {
        title
        description
      }
    }
  }
`


// featuredImage {
//     childImageSharp {
//       fluid(maxWidth: 1200) {
//         ...GatsbyImageSharpFluid
//       }
//     }
// }