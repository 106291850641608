import React from 'react'
import AppLayout from './AppMarkdown'
import Header from '../header'
import Footer from '../footer'
import Heading from '../UI/Heading'
import Bounds from '../Bounds'
import useRenderAst from '../../hooks/useRenderAst'

const ClassicLayout = ({ data, location, children }) => {
    const page = data.markdownRemark
    const renderAst = useRenderAst()

    return (
        <AppLayout page={page} location={location}>
            <Header />

            <Bounds>
                <Heading as="h1" itemProp="headline">{page.frontmatter.title}</Heading>

                {renderAst(page.htmlAst)}
                {children}
            </Bounds>

            <Footer />
        </AppLayout>
    )
}

export default ClassicLayout
