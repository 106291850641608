import styled from '@emotion/styled'
import { width, height, maxWidth, space, color, display } from 'styled-system'

const Img = styled.img`
    ${width};
    ${height};
    ${maxWidth};
    ${space};
    ${color};
    ${display};
`

Img.propTypes = {
    ...width.propTypes,
    ...height.propTypes,
    ...maxWidth.propTypes,
    ...space.propTypes,
    ...color.propTypes,
    ...display.propTypes
}

export default Img
