import React from 'react'
import styled from '@emotion/styled'
import { space, border } from 'styled-system'

const Input = styled.input`
    padding: 20px 10px;
    background: white;
    color: #222;
    width: 100%;
    margin: 0;
    border: none;
    border-radius: 4px;
    ${space};
    ${border};

    &:focus {
        outline: none;
    }
`

Input.propTypes = {
    ...space.propTypes,
    ...border.propTypes
}

export default Input